<template>
  <div class="flex items-center justify-center">
    <a
      class="relative flex max-w-md p-4 px-6 mt-6 duration-200 bg-white border-2 border-dashed rounded-lg cursor-pointer hover:border-gray-400 hover:bg-gray-800 hover:text-white tranform"
      href="https://finestdevs.com/figma-access"
    >      
      <div class="relative">
        <div class="absolute flex w-3 h-3 pointer-events-none" style="top:-3px; left:-3px">
          <span
            class="absolute inline-flex w-full h-full bg-pink-400 rounded-full opacity-75 animate-ping"
          ></span>
          <span
            class="relative inline-flex w-3 h-3 bg-pink-500 rounded-full"
          ></span>
        </div>
        <img src="/figma.png" alt="figma" class="w-24">
      </div>
      <div class="flex flex-col">      
        <h1 class="block pl-4 text-lg font-bold">
          Unlock 110+ Figma Templates
        </h1>
        <p class="block px-6">
          Get instant access to 110+ Figma templates (and 110+ Framer templates as bonus).
        </p>
      </div>
    </a>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.ping {
  position: absolute;
  background: #fff;
  border-radius: 50%;
  border: 50px solid #222;
  width: 20px;
  height: 20px;
  animation: load 1.5s ease-out infinite;
}

@keyframes load {
  0% {
    background: #fff;
    border: 0px solid #fff;
  }
  50% {
    background: #fff;
    border: 100px solid #222;
  }
  100% {
    background: #fff;
    border: 0px solid #222;
  }
}
</style>
