<template>
  <div class="z-50">
    <div class="flex justify-between mx-6 my-3">
      <div class="flex items-center">
        <a
          href="https://bit.ly/tabler-icons-tweetme"
          target="_blank"
          rel="nofollow"
          class="flex"
        >
          <div class="self-start">Tweet Me</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="self-center ml-2 text-blue-400"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <path
              d="M22 4.01c-1 .49-1.98.689-3 .99-1.121-1.265-2.783-1.335-4.38-.737S11.977 6.323 12 8v1c-3.245.083-6.135-1.395-8-4 0 0-4.182 7.433 4 11-1.872 1.247-3.739 2.088-6 2 3.308 1.803 6.913 2.423 10.034 1.517 3.58-1.04 6.522-3.723 7.651-7.742a13.84 13.84 0 0 0 .497 -3.753C20.18 7.773 21.692 5.25 22 4.009z"
            />
          </svg>
        </a>
        <a
          href="https://bit.ly/tabler-icons-share"
          target="_blank"
          rel="nofollow"
          class="flex ml-6"
        >
          <div class="self-start">Share</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="self-center text-blue-700"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <path
              d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"
            />
          </svg>
        </a>
      </div>
      <!-- <div class="flex">
        <div class="self-start">
          <a href="https://github.com/tabler/tabler-icons" target="_blank"
            >GitHub</a
          >
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="self-center ml-1"
        >
          <path
            d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"
          />
        </svg>
      </div> -->

      <div class="flex items-center ml-4 space-x-2">
        <img src="/ckissi.jpeg" alt="ckissi" class="w-8 rounded-full" />
        <a
          href="https://bit.ly/3bTJdLa"
          rel="nofollow"
          class="block px-3 py-2 font-bold text-blue-500 no-underline hover:text-black hover:border-2 hover:border-gray-800 focus:outline-none"
          >Follow Me on Twitter</a
        >
      </div>
    </div>
    <div class="my-8 md:my-24">
      <h1 class="text-3xl font-semibold md:text-5xl">Tabler Icons</h1>
      <h2>
        {{ count }} Open source free SVG icons. Highly customizable. No
        attribution required. For commercial use.
      </h2>
      <!-- <div class="mt-8 text-center">
        <a
          href="https://github.com/tabler/tabler-icons/archive/master.zip"
          class="p-3 px-4 text-white bg-purple-700 border-2 border-purple-700 rounded-md shadow-purple-xl hover:bg-white hover:text-purple-600"
        >Download Icons</a>
      </div>-->
      <!-- <NewsletterBox source="tablericons-top" /> -->
      <!-- <ShareBox></ShareBox> -->
      <!-- <twidesk-box></twidesk-box> -->
      <!-- <getrevue></getrevue> -->
      <!-- <Consultation /> -->
      <!-- <florin-ebook></florin-ebook> -->
      <!-- <html-2-react></html-2-react> -->
      <sponsored></sponsored>
    </div>
  </div>
</template>

<script>
import Sponsored from "./Sponsored.vue";
//import Incleveri from "./Incleveri.vue";
// import Consultation from "./Consultation.vue";
//import Html2React from "./Html2React.vue";
//import Getrevue from "./Getrevue.vue";
//import TwideskBox from "./TwideskBox.vue";
//import NewsletterBox from "./NewsletterBox.vue";
//import ShareBox from "./ShareBox.vue";

export default {
  components: {
    Sponsored,
    //Incleveri,
    //NewsletterBox,
    //ShareBox
    //TwideskBox,
    //Getrevue,
    //Consultation,
    //Html2React,
  },
  props: {
    count: {
      type: String,
      default: "450+",
    },
  },
  data() {
    return {
      success: false,
      loading: false,
      email: "",
      error: false,
      msg: "",
      btntext: "Join Now",
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
